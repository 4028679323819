import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"
import Creations from "src/components/sections/creations/Creations"

const LabPage = ({ data: { lab } }) => {
  const sections = useContentfulSection(lab.sections)
  const meta = useMeta(lab)

  return (
    <Wrap>
      <Layout hideFooter>
        <Seo {...meta} />
        <Nav dark />
        <Creations {...sections.lab} />
      </Layout>
    </Wrap>
  )
}

const Wrap = styled.div`
  padding-top: 15vh;
  background: #181818;
  --section-background-color: #181818;
  --creations-title-color: white;
  --creations-tags-color: #ababab;
  --creation-name-color: white;
  --creation-tag-color: #898d96;
  min-height: calc(var(--vh, 1vh) * 100);
`

export const query = graphql`
  {
    lab: contentfulPage(pageId: { eq: "lab" }) {
      ...Page
    }
  }
`

export default LabPage
